import { Lot, LotAvailable, LotDetails, PagedResponse } from '~/models'
import { SpecForm, ReviewArchitecturalForm } from '~/models/forms'
import { $axios } from '~/utils/api'

const END_POINT = '/lot'
const TABLES_END_POINT = '/lots/table'

const getLotDetail = (slug: string): Promise<LotDetails> =>
  $axios.$get(`${END_POINT}/${slug}/details`)

const getLotNeighbors = (slug: string): Promise<any[]> =>
  $axios.$get(`${END_POINT}/${slug}/neighbors`)

const postSpec = (slug: string, form: SpecForm): Promise<any> =>
  $axios.$post(`${END_POINT}/${slug}/add_spec`, form)

const draftSpec = (slug: string, form: SpecForm): Promise<any> =>
  $axios.$post(`${END_POINT}/${slug}/draft_spec`, form)

const putSpec = (slug: string, form: SpecForm): Promise<any> =>
  $axios.$post(`${END_POINT}/${slug}/update_spec`, form)

const deleteSpec = (slug: string): Promise<void> =>
  $axios.$delete(`${END_POINT}/${slug}/delete_spec`)

const clearSpec = (slug: string): Promise<void> =>
  $axios.$put(`${END_POINT}/${slug}/clear/spec`)

const getLot = (slug: string): Promise<Lot> =>
  $axios.$get(`${END_POINT}/${slug}`)

const getLots = (
  projectId: number,
  elevationId: number
): Promise<LotAvailable[]> =>
  $axios.$get(
    `v2${END_POINT}s/sites_to_build?project_id=${projectId}&elevation_id=${elevationId}`
  )

const addBuild = (slug: string, form: any): Promise<any> =>
  $axios.$post(`${END_POINT}/${slug}/add_build`, form)

const editBuild = (slug: string, from: any): Promise<any> =>
  $axios.$post(`${END_POINT}/${slug}/edit_build`, from)

const cancelBuild = (slug: string, form: any) =>
  $axios.$patch(`${END_POINT}/${slug}/cancel_build`, form)

const addOrEditContract = (slug: string, form: any) =>
  $axios.$post(`${END_POINT}/${slug}/contract`, form)

const cancelContract = (slug: string, form: any) =>
  $axios.$post(`${END_POINT}/${slug}/cancel_contract`, form)

const reviewArchitectural = (slug: string, form: ReviewArchitecturalForm) =>
  $axios.$post(`${END_POINT}/${slug}/review_architectural`, form)

const patchLot = (slug: string, form: any): Promise<LotDetails> =>
  $axios.$patch(`${END_POINT}/${slug}`, form)

const getLotTable = (
  type: string,
  page?: number,
  pageSize?: number,
  builder?: number
): Promise<PagedResponse<any>> =>
  $axios.$get(
    `${TABLES_END_POINT}/${type}${page ? `?page=${page}` : ''}${
      pageSize ? `&page_size=${pageSize}` : ''
    }${builder ? `&builder=${builder}` : ''}`
  )

const search = (toSearch: string, queryParams?: string) =>
  $axios.$get(
    `${END_POINT}?search=${toSearch}${queryParams ? `&${queryParams}` : ''}`
  )

const updateNeighbors = (slug: string, formData: any): Promise<any[]> =>
  $axios.$put(`${END_POINT}/${slug}/update_neighbors`, formData)

const uploadLotDocument = (slug: string, formData: FormData) =>
  $axios.$post(`${END_POINT}/${slug}/upload_document`, formData)

const updateTableLot = (id: number, data: any) =>
  $axios.$put(`${TABLES_END_POINT}/${id}`, data)

const updateBulkLots = (lots: Lot[]) =>
  $axios.$put(`${TABLES_END_POINT}/bulk`, lots)

export {
  patchLot,
  getLotDetail,
  getLotNeighbors,
  postSpec,
  getLot,
  getLots,
  deleteSpec,
  putSpec,
  addBuild,
  editBuild,
  cancelBuild,
  addOrEditContract,
  cancelContract,
  reviewArchitectural,
  getLotTable,
  search,
  updateNeighbors,
  uploadLotDocument,
  draftSpec,
  updateTableLot,
  updateBulkLots,
  clearSpec,
}
