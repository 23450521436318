import { getContracts as getBuilderContracts } from '~/api/builder.api'
import { getFilters } from '~/api/filters.api'

const getContracts = async (builder_id: number): Promise<any> => {
  if (!builder_id) {
    throw new Error('No builder ID found to get contracts')
  }

  return await getBuilderContracts(builder_id)
}

const getBuilders = async (): Promise<any> => {
  return await getFilters('builder_values')
}

export { getContracts, getBuilders }
